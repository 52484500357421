var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { eIndicateurType } from "adwone-engine/index.bin";
import { ref_Campaigns } from "../dto/client/ref_Campaigns.bin";
import { AggregatorBase } from "./AggregatorBase";
import { AggregatorFactory } from "./AggregatorFactory";
import { eKPIType, KPIsManagerCache } from "../models/KPIsManager.bin";
import { eColumnType } from "../models/types.bin";
import { GetComputedIO, GetTimeIOs } from "./IndicateurListing";
import { clone, distinct, propertyOf, Typed } from "../tools.bin";
import { Trad, TradProp } from "trad-lib";
import { ref_Messages } from "../dto/client/ref_Messages.bin";
var CampaignAggregator = /** @class */ (function (_super) {
    __extends(CampaignAggregator, _super);
    function CampaignAggregator() {
        var _this = _super.call(this, ref_Campaigns) || this;
        _this.ProvideMessagesOptions = function () { return __awaiter(_this, void 0, void 0, function () {
            var msgAggregator, msgKpis, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        msgAggregator = AggregatorFactory.GetInstance().Get(ref_Messages);
                        _a = clone;
                        return [4 /*yield*/, msgAggregator.Provide()];
                    case 1:
                        msgKpis = _a.apply(void 0, [(_b.sent())]);
                        msgKpis.forEach(function (i) {
                            i.indicateur.name = "Message ".concat(i.indicateur.name);
                            if (!i.indicateur.options)
                                i.indicateur.options = {};
                            i.indicateur.options.linksDescriptor = {
                                className: ref_Messages.name,
                                property: propertyOf('Campaign')
                            };
                        });
                        return [2 /*return*/, msgKpis];
                }
            });
        }); };
        _this.Provide = function () { return __awaiter(_this, void 0, void 0, function () {
            var propertyIOs, kpiIOs, computedIOs, netFO_IO, budget_IO, properties, budgetKpiType, numberTotal_IO, pagination_IO, contents, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.MetadataToIndicateurOptions()];
                    case 1:
                        propertyIOs = _b.sent();
                        return [4 /*yield*/, this.KPIToIndicateurOptions()];
                    case 2:
                        kpiIOs = (_b.sent())
                            .filter(function (io) { return io.indicateur.field != "TotalCount"; });
                        computedIOs = [];
                        netFO_IO = kpiIOs.find(function (i) { return i.indicateur.field == "NetFO"; });
                        budget_IO = kpiIOs.find(function (i) { return i.indicateur.field == "Budget"; });
                        return [4 /*yield*/, KPIsManagerCache.GetInstance(this._objectType.name).GetUserProperties()];
                    case 3:
                        properties = _b.sent();
                        budgetKpiType = eKPIType.Price;
                        /* Désactivé : https://www.wrike.com/open.htm?id=1565984185
                        if (!properties.some(p => p.name == "Support")) {
                            budgetKpiType = eKPIType.PriceReturned;
                            const price_IOs = kpiIOs.filter(i => i.indicateur.valueType == eKPIType.Price);
                            price_IOs.forEach(io => io.indicateur.valueType = budgetKpiType);
                        }*/
                        if (netFO_IO && budget_IO)
                            computedIOs.push(GetComputedIO(budgetKpiType, "Remaining Budget", "-", [budget_IO.indicateur, netFO_IO.indicateur]));
                        numberTotal_IO = GetComputedIO(eKPIType.Decimal, "Nombre total", "+", [
                            kpiIOs.find(function (p) { return p.indicateur.name == Trad("Nombre gracieux"); }).indicateur,
                            kpiIOs.find(function (p) { return p.indicateur.name == Trad("Nombre payant"); }).indicateur
                        ]);
                        computedIOs.push(numberTotal_IO);
                        pagination_IO = kpiIOs.find(function (i) { return i.indicateur.field == "Pagination"; });
                        if (pagination_IO)
                            computedIOs.push(GetComputedIO(eKPIType.Decimal, "Remaining Pages", "-", [pagination_IO.indicateur, numberTotal_IO.indicateur]));
                        _a = [__spreadArray(__spreadArray(__spreadArray([], propertyIOs, true), kpiIOs, true), computedIOs, true)];
                        return [4 /*yield*/, this.ProvideMessagesOptions()];
                    case 4:
                        contents = __spreadArray.apply(void 0, _a.concat([_b.sent(), true]));
                        return [2 /*return*/, this.Sort_IO(contents)];
                }
            });
        }); };
        return _this;
    }
    CampaignAggregator.prototype.GetDefaultColumnNames = function () {
        return __awaiter(this, void 0, void 0, function () {
            var properties, columnNames;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, KPIsManagerCache.GetInstance(this._objectType.name).GetUserProperties()];
                    case 1:
                        properties = _a.sent();
                        columnNames = [];
                        if (properties.some(function (p) { return p.name == "Advertiser"; }))
                            columnNames = __spreadArray(__spreadArray([], columnNames, true), [
                                TradProp("Name", this._objectType),
                                TradProp("Start", this._objectType),
                                TradProp("End", this._objectType),
                                TradProp("Source", this._objectType),
                                TradProp("AdvertiserGroup", this._objectType),
                                TradProp("Advertiser", this._objectType),
                                TradProp("Brand", this._objectType),
                                TradProp("Product", this._objectType),
                                TradProp("Departments", this._objectType),
                                Trad("Budget"),
                                Trad("Nombre de messages"),
                                Trad("Net"),
                                TradProp("EstimateNumber", this._objectType),
                                TradProp("ReturnedCurrency", this._objectType),
                                TradProp("Created_by", this._objectType)
                            ], false);
                        if (properties.some(function (p) { return p.name == "Support"; }))
                            columnNames = __spreadArray(__spreadArray([], columnNames, true), [
                                TradProp("Media", this._objectType),
                                TradProp("Departments", this._objectType),
                                TradProp("Support", this._objectType),
                                TradProp("BroadcastArea", this._objectType),
                                TradProp("Start", this._objectType),
                                TradProp("End", this._objectType),
                                TradProp('ModelProperties.Periodicity', this._objectType),
                                TradProp('ModelProperties.MediaFamily', this._objectType),
                                TradProp("Name", this._objectType),
                                Trad("Budget"),
                                Trad("Net"),
                                Trad("Remaining Budget"),
                                TradProp("Currency", this._objectType),
                                TradProp("EstimateNumber", this._objectType),
                                Trad("Pagination"),
                                Trad("Nombre total"),
                                Trad("Remaining Pages"),
                                Trad("Nombre de messages"),
                                Trad("internal_comment"),
                                Trad("external_comment"),
                                TradProp("Created_by", this._objectType)
                            ], false);
                        return [2 /*return*/, [{ name: Trad('default'), colNames: distinct(columnNames) }]];
                }
            });
        });
    };
    CampaignAggregator.prototype.GetDefaultVentilationNames = function () {
        return __awaiter(this, void 0, void 0, function () {
            var properties;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, KPIsManagerCache.GetInstance(this._objectType.name).GetUserProperties()];
                    case 1:
                        properties = _a.sent();
                        if (properties.some(function (p) { return p.name == "Support"; }))
                            return [2 /*return*/, [TradProp("Support", this._objectType)]];
                        return [2 /*return*/, [TradProp("Advertiser", this._objectType)]];
                }
            });
        });
    };
    CampaignAggregator.prototype.GetForcedIndicateurs = function () {
        return {
            'ModelProperties.MediaFamily': this.GetMediaFamilyII(),
            'ModelProperties.Periodicity': this.GetPeriodicityII(),
            'Agency_Res': this.GetAgencyResII(),
        };
    };
    CampaignAggregator.prototype.MetadataToIndicateurOptions = function () {
        return __awaiter(this, void 0, void 0, function () {
            var propertyIOs, departmentsIO, attachment_IO;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, _super.prototype.MetadataToIndicateurOptions.call(this)];
                    case 1:
                        propertyIOs = _a.sent();
                        departmentsIO = Typed({
                            indicateur: {
                                type: eIndicateurType.info,
                                name: TradProp("Departments", this._objectType),
                                valueType: eKPIType.Rid,
                                field: "Departments"
                            },
                            columnType: eColumnType.Property
                        });
                        attachment_IO = function (type) { return Typed({
                            indicateur: {
                                type: eIndicateurType.info,
                                name: Trad("".concat(type.toLowerCase(), "_comment")),
                                valueType: eKPIType.String,
                                field: "Attachments",
                                options: {
                                    subProperty: "".concat(type, ".Text"),
                                    formater: { type: "html" }
                                }
                            },
                            columnType: eColumnType.Property
                        }); };
                        return [2 /*return*/, __spreadArray(__spreadArray(__spreadArray([], propertyIOs, true), [departmentsIO, attachment_IO("Internal"), attachment_IO("External")], false), GetTimeIOs(), true)];
                }
            });
        });
    };
    return CampaignAggregator;
}(AggregatorBase));
export { CampaignAggregator };
