var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { FormatCellsMemoized } from "adwone-engine/EngineTools";
import { CreateIndicateur, IndicateurToString } from "adwone-engine/index.bin";
import { Format, GetCellTemplate } from "format-lib/index.bin";
import { clone, GetSubElement, JoinElements, toArray } from "../../tools.bin";
import { ref_ConfigurationsBase } from "./ref_ConfigurationsBase.bin";
import { ref_Messages } from "./ref_Messages.bin";
import { ePropType } from "../../models/VertexProperty.bin";
import { RightManager } from "../../models/types/rights.bin";
import { GetCurrentLocale } from "trad-lib";
var ref_SchedulerConfigurations = /** @class */ (function (_super) {
    __extends(ref_SchedulerConfigurations, _super);
    function ref_SchedulerConfigurations() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.Active = true;
        _this.Default = false;
        _this.Name = "default name";
        _this.LabelBuildTop = [];
        _this.LabelBuild = [];
        _this.LabelBuildBottom = [];
        _this.Style = {};
        _this.StyleDefault = null;
        return _this;
    }
    return ref_SchedulerConfigurations;
}(ref_ConfigurationsBase));
export { ref_SchedulerConfigurations };
var priority = [
    "Placement",
    "Format",
    "Campaign",
    "Product",
    "Brand",
    "Advertiser",
    "AdvertiserGroup",
    "BroadcastArea",
    "Support",
    "AdvCompany_Fin",
    "AdvCompany_Com",
    "Media",
    "Currency",
    "ModelProperties.AdCreation"
];
export function GetKeyTemplates(key, messages, style) {
    var allRids = messages.map(function (m) { return GetSubElement(m, key); })
        .map(function (v) { return Array.isArray(v) ? v : [v]; })
        .reduce(function (a, b) { return a.concat(b); }, [])
        .filter(function (v) { return typeof v === "string"; });
    var uniqRids = Array.from(new Set(allRids));
    var res = uniqRids
        .filter(function (_rid) { return style === null || style === void 0 ? void 0 : style[_rid]; })
        .map(function (_rid) { return (clone(__assign(__assign({}, style === null || style === void 0 ? void 0 : style[_rid]), { rid: _rid }))); });
    return res;
}
export function GetTemplate(messages, style) {
    if (!style || !messages)
        return null;
    for (var _i = 0, priority_1 = priority; _i < priority_1.length; _i++) {
        var k = priority_1[_i];
        var res = GetKeyTemplates(k, messages, style);
        if (res.length === 1) {
            return res[0];
        }
    }
    return null;
}
export function GetTemplates(messages, style) {
    if (!style || !messages)
        return null;
    return priority.flatMap(function (k) { return GetKeyTemplates(k, messages, style); });
}
export function BuildLabel(messages, template, allMetadata, objProvider, labelField) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    if (labelField === void 0) { labelField = 'LabelBuild'; }
    return __awaiter(this, void 0, void 0, function () {
        var text, _loop_1, _i, _k, elmnt, textTrim, textNoEmpty;
        return __generator(this, function (_l) {
            switch (_l.label) {
                case 0:
                    if (!messages)
                        return [2 /*return*/];
                    text = [];
                    if (!(template === null || template === void 0 ? void 0 : template[labelField])) return [3 /*break*/, 4];
                    _loop_1 = function (elmnt) {
                        var memoDependencies, indInstance, value, cellVal, formatedCell, _m, metadataProp, cellTemplate, _o, rids, objts, strs;
                        return __generator(this, function (_p) {
                            switch (_p.label) {
                                case 0:
                                    memoDependencies = {
                                        userRid: (_a = RightManager.GetUser()) === null || _a === void 0 ? void 0 : _a["@rid"],
                                        locale: GetCurrentLocale()
                                    };
                                    if (!(elmnt.Type === "indicateur")) return [3 /*break*/, 3];
                                    indInstance = CreateIndicateur(elmnt.Value);
                                    return [4 /*yield*/, indInstance.Compute(messages)];
                                case 1:
                                    value = _p.sent();
                                    cellVal = [{ Value: value, IndicateurSignature: IndicateurToString(indInstance), Type: 'cell', Formated: '' }];
                                    _m = toArray;
                                    return [4 /*yield*/, FormatCellsMemoized(ref_Messages.name, indInstance, cellVal, memoDependencies)];
                                case 2:
                                    formatedCell = _m.apply(void 0, [_p.sent()])[0];
                                    text.push((_b = formatedCell === null || formatedCell === void 0 ? void 0 : formatedCell.Formated) !== null && _b !== void 0 ? _b : value);
                                    return [2 /*return*/, "continue"];
                                case 3:
                                    if (!(elmnt.Type === "property")) return [3 /*break*/, 10];
                                    metadataProp = allMetadata.find(function (meta) { return meta.name == elmnt.Value; });
                                    cellTemplate = GetCellTemplate(ePropType.Date);
                                    if (!metadataProp) return [3 /*break*/, 9];
                                    _o = metadataProp.type;
                                    switch (_o) {
                                        case ePropType.Date: return [3 /*break*/, 4];
                                        case ePropType.Link: return [3 /*break*/, 5];
                                        case ePropType.LinkSet: return [3 /*break*/, 5];
                                        case ePropType.LinkList: return [3 /*break*/, 5];
                                    }
                                    return [3 /*break*/, 8];
                                case 4:
                                    if (elmnt.Value == "Start")
                                        text.push(cellTemplate(new Date((_d = (_c = __spreadArray([], messages, true).sort(function (a, b) { return new Date(a[elmnt.Value]).getTime() - new Date(b[elmnt.Value]).getTime(); })) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d[elmnt.Value])));
                                    else if (elmnt.Value == "End")
                                        text.push(cellTemplate(new Date((_f = (_e = __spreadArray([], messages, true).sort(function (a, b) { return new Date(b.End).getTime() - new Date(a.End).getTime(); })) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.End)));
                                    else
                                        text.push(cellTemplate(new Date((_h = (_g = __spreadArray([], messages, true).sort(function (a, b) { return new Date(a[elmnt.Value]).getTime() - new Date(b[elmnt.Value]).getTime(); })) === null || _g === void 0 ? void 0 : _g[0]) === null || _h === void 0 ? void 0 : _h[elmnt.Value])));
                                    return [3 /*break*/, 9];
                                case 5:
                                    rids = messages
                                        .map(function (m) { return GetSubElement(m, elmnt.Value); })
                                        .filter(function (v) { return v; });
                                    if (!rids.length) return [3 /*break*/, 7];
                                    return [4 /*yield*/, objProvider(metadataProp.linkedClass, { "@rid": rids }, memoDependencies)];
                                case 6:
                                    objts = (_j = (_p.sent())) !== null && _j !== void 0 ? _j : [];
                                    strs = Array.from(new Set(objts.map(function (o) { return Format(o); })));
                                    text.push(JoinElements(strs));
                                    _p.label = 7;
                                case 7: return [3 /*break*/, 9];
                                case 8:
                                    text.push(JoinElements(messages.map(function (m) { var _a, _b; return (_b = (_a = GetSubElement(m, elmnt.Value)) === null || _a === void 0 ? void 0 : _a.toString) === null || _b === void 0 ? void 0 : _b.call(_a); })));
                                    return [3 /*break*/, 9];
                                case 9: return [2 /*return*/, "continue"];
                                case 10:
                                    console.error('element not iplemented.', elmnt);
                                    return [2 /*return*/];
                            }
                        });
                    };
                    _i = 0, _k = toArray(template === null || template === void 0 ? void 0 : template[labelField]);
                    _l.label = 1;
                case 1:
                    if (!(_i < _k.length)) return [3 /*break*/, 4];
                    elmnt = _k[_i];
                    return [5 /*yield**/, _loop_1(elmnt)];
                case 2:
                    _l.sent();
                    _l.label = 3;
                case 3:
                    _i++;
                    return [3 /*break*/, 1];
                case 4:
                    textTrim = text
                        .filter(Boolean)
                        .map(function (t) {
                        if (typeof t === "string")
                            t.trim();
                        return t;
                    });
                    textNoEmpty = textTrim.filter(function (t) { return t; });
                    return [2 /*return*/, textNoEmpty];
            }
        });
    });
}
